var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card p-5"},[_c('div',{staticClass:"card__wrap"},[_c('h1',{staticClass:"card-title mb-4 mr-3"},[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("title")],2)]),_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"card__input-wrap mb-4"},[(_vm.authentication)?_c('InputBlock',{class:{
          'is-invalid': !_vm.isEmailValid && _vm.form.firstInput.length,
          'over-top': !_vm.isIntroPassed
        },attrs:{"type":"text","id":"1","label":_vm.label,"name":"firstInput","new-class":_vm.isIntroPassed ? '' : 'over-top'},on:{"input":function($event){_vm.errorMessage = ''}},model:{value:(_vm.form.firstInput),callback:function ($$v) {_vm.$set(_vm.form, "firstInput", $$v)},expression:"form.firstInput"}}):_vm._e(),(_vm.registration)?_c('InputBlock',{class:{
          'is-invalid': !_vm.isInnValid && _vm.form.inn.length,
          'over-top': !_vm.isIntroPassed
        },attrs:{"type":"text","label":"ИНН","id":"3","new-class":_vm.isIntroPassed ? '' : 'over-top'},model:{value:(_vm.form.inn),callback:function ($$v) {_vm.$set(_vm.form, "inn", $$v)},expression:"form.inn"}}):_vm._e(),_c('InputBlock',{class:{
          'is-invalid':
            _vm.needSecondValidation && !_vm.isEmailValid && _vm.form.secondInput.length,
          'over-top': !_vm.isIntroPassed
        },attrs:{"type":_vm.type,"id":"2","label":_vm.secondLabel,"name":"secondInput","new-class":_vm.isIntroPassed ? '' : 'over-top'},on:{"input":function($event){_vm.errorMessage = ''}},model:{value:(_vm.form.secondInput),callback:function ($$v) {_vm.$set(_vm.form, "secondInput", $$v)},expression:"form.secondInput"}})],1),(_vm.errorMessage.length)?_c('div',{staticClass:"error over-top"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.auth)?_c('router-link',{staticClass:"card__recovery-password mb-4 over-top",attrs:{"to":"/recovery-password"}},[_vm._v("Забыли пароль?")]):_vm._e(),_c('div',{staticClass:"card__btn-wrap"},[_c('b-button',{staticClass:"btn btn-second mb-3 login-register-button",class:{ 'over-top': !_vm.isIntroPassed },attrs:{"type":"button","disabled":!_vm.canSubmitData},on:{"click":_vm.onClick}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.btnText))]):_c('b-spinner',{attrs:{"small":""}})],1),_c('router-link',{staticClass:"card__link over-top",attrs:{"to":_vm.link}},[_c('h3',{staticClass:"card__subtitle"},[_vm._v(_vm._s(_vm.subtitle))])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }