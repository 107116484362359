<template>
  <TepmplateBlock modClass>
    <div class="row registration-wrap">
      <FormStandart
        :authentication="authentication"
        title="Войти"
        subtitle="Регистрация"
        link="/registration"
        label="E-mail"
        secondLabel="Пароль"
        type="password"
        btn-text="Подтвердить"
        :auth="true"
        @login="login"
        :error="error || ''"
        :needSecondValidation="false"
        :loading="isDataSubmitting"
        :intro="!isIntroPassed"
        :isIntroPassed="true"
      />
    </div>
    <div v-if="!isIntroPassed" class="dark-intro-background">
      <div
        class="intro-tip-wrapper d-flex align-items-end justify-content-center"
      >
        <!-- <div class="intro-target"></div> -->
        <div class="intro-tip mx-5 mb-5 w-50 text-center">
          Загляните в почту, указанную при регистрации. <br />Там должно лежать
          письмо с реквизитами доступа. <br />Введите Email и Пароль, указанные
          в письме, в соответствующие поля формы и нажмите кнопку "Подтвердить"
        </div>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import FormStandart from "../../components/Form/FormStandart";
import TepmplateBlock from "../../components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";

export default {
  name: "Auth",
  components: { TepmplateBlock, FormStandart },
  data() {
    return {
      authentication: false,
      error: "",
      isDataSubmitting: false,
      isIntroPassed: true,

      demoModeOn: false,
      demoMode_step1_adminProfile: false,
      demoMode_step2_generalInfo: false,
      demoMode_step3_bankAccount: false,
      demoMode_step4_officeAdded: false,
      demoMode_step5_nameSaved: false,
      demoMode_step6_firstTaskCreated: false
    };
  },
  computed: {},
  methods: {
    async login(data) {
      if (!data.email.length || !data.password.length) {
        this.isDataSubmitting = false;
        this.$bvToast.toast("Пожалуйста заполните все поля", {
          variant: "danger",
          title: "Ошибка"
        });
      } else {
        this.isDataSubmitting = true;
        this.error = "";
        const res = await this.$store.dispatch("wakeAuth", data);
        this.isDataSubmitting = false;
        if (!res.err) {
          if (localStorage.getItem("shop-first")) {
            this.$router.replace("/shop");
          } else {
            if (this.$route.query && this.$route.query.redirect) {
              const mainCompany = await RequestManager().getMainCompanyInfo();
              window.localStorage.setItem(
                "mainCompanyInfo",
                JSON.stringify(mainCompany)
              );
              await this.$router.replace(this.$route.query.redirect);
            } else {
              await this.$router.push("/main");
            }
          }
        } else {
          this.error = "Не верный логин и/или пароль.";
        }
      }
    }
  },
  created() {
    console.log("login created");
    window.localStorage.setItem("currentUserId", "");
    window.localStorage.setItem("currentUserCompany", "");
    this.$store.dispatch("logout");
    this.authentication = true;
    if (this.$route.params.first) {
      this.isIntroPassed = false;
      localStorage.setItem("isIntroPassed", "false");
    } else {
      this.isIntroPassed = true;
      localStorage.setItem("isIntroPassed", "true");
    }

    // ssa inputed
  }
};
</script>

<style lang="scss" scoped>
.registration-wrap {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
