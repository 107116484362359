<template>
  <div class="card p-5">
    <div class="card__wrap">
      <h1 class="card-title mb-4 mr-3">
        {{ title }} <slot name="title"></slot>
      </h1>
    </div>

    <form @keyup.enter="onClick">
      <div class="card__input-wrap mb-4">
        <InputBlock
          v-if="authentication"
          v-model="form.firstInput"
          type="text"
          id="1"
          :label="label"
          name="firstInput"
          @input="errorMessage = ''"
          :new-class="isIntroPassed ? '' : 'over-top'"
          :class="{
            'is-invalid': !isEmailValid && form.firstInput.length,
            'over-top': !isIntroPassed
          }"
        >
        </InputBlock>

        <InputBlock
          v-if="registration"
          v-model="form.inn"
          type="text"
          label="ИНН"
          id="3"
          :new-class="isIntroPassed ? '' : 'over-top'"
          :class="{
            'is-invalid': !isInnValid && form.inn.length,
            'over-top': !isIntroPassed
          }"
        />

        <InputBlock
          v-model="form.secondInput"
          :type="type"
          id="2"
          :label="secondLabel"
          name="secondInput"
          @input="errorMessage = ''"
          :new-class="isIntroPassed ? '' : 'over-top'"
          :class="{
            'is-invalid':
              needSecondValidation && !isEmailValid && form.secondInput.length,
            'over-top': !isIntroPassed
          }"
        >
        </InputBlock>
      </div>
      <div v-if="errorMessage.length" class="error over-top">
        {{ errorMessage }}
      </div>
      <router-link
        class="card__recovery-password mb-4 over-top"
        v-if="auth"
        to="/recovery-password"
        >Забыли пароль?</router-link
      >

      <div class="card__btn-wrap">
        <b-button
          type="button"
          @click="onClick"
          class="btn btn-second mb-3 login-register-button"
          :class="{ 'over-top': !isIntroPassed }"
          :disabled="!canSubmitData"
        >
          <span v-if="!loading">{{ btnText }}</span>

          <b-spinner v-else small></b-spinner>
        </b-button>

        <router-link class="card__link over-top" :to="link">
          <h3 class="card__subtitle">{{ subtitle }}</h3>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import InputBlock from "./FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import { SET_ROLE, SET_AUTH, SET_CURRENT_USER } from "../../store/types";
import { mapActions } from "vuex";

const emailValidationRegexp = /^[^@]+@\w+(\.\w+)+\w$/;
const innValidationRegexp = /^\d{10}(?:\d{2})?$/;

// import axios from "axios";
export default {
  components: {
    InputBlock
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    authentication: {
      boolean: false
    },
    registration: {
      boolean: false
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    link: {
      type: String
    },
    label: {
      type: String
    },
    secondLabel: {
      type: String
    },
    btnText: {
      type: String
    },
    type: {
      type: String
    },
    auth: {
      type: Boolean
    },
    error: {
      type: String
    },
    isShowPopover: {
      type: Boolean
    },
    needSecondValidation: {
      type: Boolean,
      default: () => false
    },
    isIntroPassed: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      errorMessage: "",
      form: {
        firstInput: "",
        secondInput: "",
        inn: ""
      }
      //isDataSubmitting: false
    };
  },

  methods: {
    onClick() {
      if (this.auth) {
        this.login(this.form);
      } else {
        this.register(this.form);
      }
    },
    ...mapActions({ SET_ROLE, SET_AUTH, SET_CURRENT_USER }),
    login(formData) {
      //this.isDataSubmitting = true;
      const data = {
        email: formData.firstInput.toLowerCase(),
        password: formData.secondInput
      };
      this.$emit("login", data);
    },
    register(formData) {
      //this.isDataSubmitting = true;
      const data = {
        inn: formData.inn,
        company: "name",
        email: formData.secondInput.toLowerCase()
      };
      this.$emit("loadingChange", true);
      RequestManager()
        .companyRegistrationV2(data)
        .then(response => {
          if (response && response?.logError?.response?.status) {
            this.$set(
              this,
              "errorMessage",
              `Не удалось зарегистрировать компанию. ${response.logError.response.data.previous.message}`
            );
          } else {
            this.$emit("success");
          }
        })
        .finally(() => this.$emit("loadingChange", false));
    }
  },
  computed: {
    isInnValid() {
      return innValidationRegexp.test(this.form.inn);
    },
    isEmailValid() {
      if (this.auth) {
        return emailValidationRegexp.test(this.form.firstInput);
      } else {
        return emailValidationRegexp.test(this.form.secondInput);
      }
    },
    canSubmitData() {
      if (this.auth) {
        if (this.needSecondValidation) {
          return this.isEmailValid && this.isInnValid;
        } else {
          return this.isEmailValid;
        }
      } else {
        return this.isInnValid && this.isEmailValid;
      }
    }
  },
  watch: {
    error() {
      this.errorMessage = this.error;
      //this.error = "";
    },
    loading(newValue) {
      console.log(newValue);
      //this.isDataSubmitting = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.form-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__wrap {
  display: flex;
  align-items: baseline;
}

.card-title {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--card-text-color);
  display: inline-block;
  width: 100%;
}

.card__btn-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__subtitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #51a2be;
}

.card__recovery-password {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration-line: underline !important;
  color: #51a2be;
}

:deep(.is-invalid .form-control) {
  border: 1px solid var(--danger);
}
:deep(.is-invalid .form-control:focus) {
  box-shadow: none;
}
</style>
